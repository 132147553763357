import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import BlogPost from "../components/blogPost"

class Blog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      numberOfShowingBlogPosts: 0,
      nonShowingBlogPosts: [],
      showingBlogPosts: [],
    }
    this.showMoreBlogPosts = this.showMoreBlogPosts.bind(this)
    this.renderBlogPosts = this.renderBlogPosts.bind(this)
  }
  render() {
    let visibleBlogPosts = this.state.showingBlogPosts
    let invisibleBlogPosts = this.state.nonShowingBlogPosts
    let emptyMessage = (
      <div className="height-box">
        <p>
          Hei, så hyggelig at du vil lese bloggen min! Jeg har dessverre ikke
          skrevet noen innlegg enda, men kom gjerne tilbake litt senere!
        </p>
        <Link to="/">Tilbake til forsiden</Link>
      </div>
    )

    return (
      <Layout>
        <div className="wrapper wrapper--tablet-width">
          <div className="margin-wrapper">
            {visibleBlogPosts.length > 0
              ? visibleBlogPosts.map(post => {
                  return post
                })
              : emptyMessage}
            {invisibleBlogPosts.length > 0 ? (
              <div
                className="blog__show-more-button"
                onClick={this.showMoreBlogPosts}
              >
                Vis flere ...
              </div>
            ) : null}
          </div>
        </div>
      </Layout>
    )
  }
  componentDidMount() {
    if (this.props.data.allSanityPost.nodes.length > 0) {
      this.renderBlogPosts()
    }
  }

  renderBlogPosts() {
    let blogPosts = this.state.showingBlogPosts

    this.props.data.allSanityPost.nodes.forEach(post => {
      let dateString = post._createdAt.replace(/[A-Z]/g, " ")
      let formattedDate = dateString.substring(0, dateString.length - 4)
      blogPosts.push(
        <BlogPost
          key={dateString}
          title={post.title}
          date={formattedDate}
          content={post._rawBody}
          author={post.author ? post.author.name : null}
        />
      )
    })

    let showingBlogPosts = blogPosts.splice(0, 1)

    this.setState({
      showingBlogPosts: showingBlogPosts,
      nonShowingBlogPosts: blogPosts,
    })
  }
  showMoreBlogPosts() {
    let visibleBlogPosts = this.state.showingBlogPosts
    let invisibleBlogPosts = this.state.nonShowingBlogPosts

    if (invisibleBlogPosts.length > 0) {
      let newPost = invisibleBlogPosts.splice(0, 1)
      visibleBlogPosts.push(newPost)

      this.setState({
        showingBlogPosts: visibleBlogPosts,
        nonShowingBlogPosts: invisibleBlogPosts,
      })
    }
  }
}

export default Blog

export const query = graphql`
  query {
    allSanityPost(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        _createdAt
        title
        _rawBody(resolveReferences: { maxDepth: 2 })
        author {
          name
        }
      }
    }
  }
`
