import React from "react"
import { serializers } from "../js/blockContentSerializer"
const BlockContent = require("@sanity/block-content-to-react")

const BlogPost = ({ date, title, content, author }) => (
  <>
    <div className="blog-post">
      <div className="blog-post__date">{date}</div>
      <h2 className="blog-post__title">{title}</h2>
      <BlockContent
        className="blog-post__content"
        projectId="6t60r0yd"
        dataset="production"
        blocks={content}
        serializers={serializers}
      />
      {author ? <div className="blog-post__author">{author}</div> : null}
    </div>
    <hr />
  </>
)

export default BlogPost
